.footer-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto 10px auto;
  height: 48px;
  width: 100%;
}

.footer-media {
  display: flex;
  align-items: center;
  user-select: none;
}

.footer-music {
  display: flex;
  align-items: center;
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  //font-family: variables.$primary-font;
  padding: 0;
  &:hover {
    height: 0;
    background: transparent;
    box-shadow: 0 0 25px 8px #54b420;
  }
}

.icon-root {
  filter: invert(1) brightness(1) contrast(0.8);
  fill: #54b420; 
  color: #54b420; 
}

.footer-socialIcon,
.footer-socialIconHover {
  width: 22px;
  height: 22px;
  color: #54b420; 
  filter: invert(1) brightness(1) contrast(0.8);
}

.footer-musicWave {
  width: 24px;
  height: 24px;
  color: #54b420;
  margin-right: 6px;
}

.footer-spacer {
  display: block;
  margin: 0 12px;
  width: 1px;
  height: 19px;
  background: #54b420;
}

.footer-youtube {
  display: flex;
  align-items: center;
  &:hover {
    height: 0;
    background: transparent;
    box-shadow: 0 0 25px 8px #54b420;
  }
}

.footer-youtubeIcon {
  width: 18px;
  height: 18px;
  color: #ffffff;
  margin-right: 7px;
}

.footer-socialLinks {
  display: flex;
}

.footer-socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  position: relative;

  &:hover {
    > .footer-socialIcon {
      color: #0a0600;
    }

    > .footer-socialIconHover {
      opacity: 0.7;
      color: #1a9e09;
    }
  }
}

.footer-socialIcon {
  width: 18px;
  height: 18px;
  transition: 0.3s;
  color: #1a9e09;
}

.footer-socialIconHover {
  color: #54b420;
  position: absolute;
  pointer-events: none;
  z-index: -2;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.footer-socialLink1 > .footer-socialIconHover {
  transform: scale(2.6);
  color: #1a9e09;
}

.footer-socialLink2 > .footer-socialIconHover {
  transform: scale(1.4);
  color: #1a9e09;
}

.footer-socialLink3 > .footer-socialIconHover {
  transform: scale(1.7);
  color: #1a9e09;
}
