@import url('https://fonts.googleapis.com/css2?family="Outfit":wght@300;600&family=Road+Rage&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Outfit", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 300;
  font-size: 12px;
  background-color: #0a0600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

input:-internal-autofill-selected {
  appearance: auto!important;
  background-image: none !important;
  background-color: inherit !important;
  color: inherit !important;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

header {
  position: relative;
  margin-left: 25px;
  margin-right: 25px;
  height: 12vh;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.app-root {
  position: relative;
  z-index: 1;
  padding: 0 100px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-image: url('../public/images/noise-background.jpg');
  background-repeat: repeat;
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    display: block;
    width: 50vw;
    height: 100%;
    background-image: url('../public/images/background-left-side.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0.7;
  }

  &:after {
    content: '';
    display: block;
    width: 50vw;
    height: 100%;
    background-image: url('../public/images/background-left-side.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(180deg);
    pointer-events: none;
    opacity: 0.7;
  }
}

nav {
  display: flex;
  justify-content: space-around;
  background-color: #333;
  padding: 1rem;
}

nav a {
  color: white;
  text-decoration: none;
}

.app-main {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: stretch;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;

  .app-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: stretch;
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 25px;
  }
}

.page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s, transform 0.5s;
}

.home .page:nth-child(1),
.mint .page:nth-child(2) {
  opacity: 1;
  transform: translateX(0);
}

.mint .page:nth-child(1) {
  transform: translateX(-100%);
}