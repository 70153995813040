.main {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.main-carousel {
  width: 100%;
}

.main-slide {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
}

.main-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 10%;
}

.main-content {
  flex: 3 1 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.main-videoPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0 0;
  width: 100%;
  max-height: 280px;
}

.main-mintContainer {
  flex: 1;
  display: flex;
  padding: 10px 0 10px;
}

.main-fakeCol {
  flex: 1 1 10%;
  min-width: 200px;
}

.main-not {
  position: absolute;
  right: 0;
  top: 200px;
}

.main-not1 {
  position: absolute;
  right: 0;
  top: 280px;
}

.main-not2 {
  position: absolute;
  right: 0;
  top: 350px;
}
