.icon-root {
    color: #ffffff;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    user-select: none;
    vertical-align: middle;
    width: 18px;
    height: 18px;
  }
  